var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full" },
      [
        _c("ag-grid-vue", {
          ref: "agGridTable",
          staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
          attrs: {
            components: _vm.cellRendererComponents,
            columnDefs: _vm.columnDefs,
            defaultColDef: _vm.defaultColDef,
            rowData: _vm.ordersList,
            gridOptions: _vm.gridOptions,
            paginationPageSize: _vm.paginationPageSize,
            pagination: true,
            enableCellTextSelection: true,
            id: "customer-order-grid",
          },
          on: {
            "first-data-rendered": _vm.onFirstDataRendered,
            "grid-size-changed": _vm.onGridSizeChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }