var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customerData
    ? _c(
        "div",
        [
          _vm.isEditForm
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { name: "id", disabled: true },
                      model: {
                        value: _vm.form.customerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customerId", $$v)
                        },
                        expression: "form.customerId",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("v-select", {
                      attrs: {
                        name: "title",
                        reduce: (x) => x.code,
                        disabled: _vm.readOnly,
                        clearable: false,
                        "append-to-body": "",
                        options: _vm.titleOptions,
                      },
                      model: {
                        value: _vm.form.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "title", $$v)
                        },
                        expression: "form.title",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("title"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:100",
                          expression: "'required|max:100'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "firstName", disabled: _vm.readOnly },
                      model: {
                        value: _vm.form.firstName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "firstName", $$v)
                        },
                        expression: "form.firstName",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("firstName"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:100",
                          expression: "'required|max:100'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "lastName", disabled: _vm.readOnly },
                      model: {
                        value: _vm.form.lastName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "lastName", $$v)
                        },
                        expression: "form.lastName",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("lastName"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            rules: {
                              required: true,
                              email: true,
                              emailExist: true,
                            },
                          },
                          expression:
                            "{\n              rules: { required: true, email:true, emailExist: true },\n            }",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "email", disabled: _vm.readOnly },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("email"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "",
                          expression: "''",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "internalRef", disabled: _vm.readOnly },
                      model: {
                        value: _vm.form.internalRef,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "internalRef", $$v)
                        },
                        expression: "form.internalRef",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("internalRef"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(6),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("v-select", {
                      attrs: {
                        reduce: (x) => x.code,
                        disabled: _vm.readOnly,
                        options: _vm.customerFormData.defaultLanguage,
                      },
                      model: {
                        value: _vm.form.languageCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "languageCode", $$v)
                        },
                        expression: "form.languageCode",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("languageCode"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(7),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.companyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "companyId", $$v)
                        },
                        expression: "form.companyId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(8),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.companyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "companyName", $$v)
                        },
                        expression: "form.companyName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(9),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("v-select", {
                      attrs: {
                        reduce: (x) => x.code,
                        disabled: _vm.readOnly,
                        options: _vm.customerFormData.defaultCurrency,
                      },
                      model: {
                        value: _vm.form.currencyCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "currencyCode", $$v)
                        },
                        expression: "form.currencyCode",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("currencyCode"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center mt-8" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-4",
                        attrs: { disabled: _vm.readOnly },
                        model: {
                          value: _vm.form.acceptMarketing,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "acceptMarketing", $$v)
                          },
                          expression: "form.acceptMarketing",
                        },
                      }),
                      _c("span", [_vm._v("Accept Marketing:")]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center mt-8" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-4",
                        attrs: { disabled: _vm.readOnly },
                        model: {
                          value: _vm.form.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isActive", $$v)
                          },
                          expression: "form.isActive",
                        },
                      }),
                      _c("span", [_vm._v("Is Active:")]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm.showMembershipFields
              ? _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _vm._m(10),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("v-select", {
                          attrs: {
                            reduce: (x) => x.code,
                            disabled: _vm.readOnly,
                            options: _vm.customerFormData.membershipLevels,
                          },
                          model: {
                            value: _vm.form.membershipId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "membershipId", $$v)
                            },
                            expression: "form.membershipId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.showMembershipFields
              ? _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _vm._m(11),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:150",
                              expression: "'max:150'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "Membership Subscription ID",
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.form.membershipSubscriptionId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "membershipSubscriptionId",
                                $$v
                              )
                            },
                            expression: "form.membershipSubscriptionId",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(
                            _vm._s(
                              _vm.errors.first("Membership Subscription ID")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.showMembershipFields
              ? _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _vm._m(12),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("flat-pickr", {
                          staticClass: "w-full",
                          attrs: {
                            config: { dateFormat: "Y-m-d" },
                            name: "Membership Expiry",
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.form.membershipExpiry,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "membershipExpiry", $$v)
                            },
                            expression: "form.membershipExpiry",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("Membership Expiry"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("vs-divider", { staticClass: "mb-5" }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-6" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("Telephone"),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.form.customerPhones, function (phone, phoneIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: phoneIndex + "0",
                      staticClass: "vx-col w-full md:w-5/6",
                    },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                          _c("div", { staticClass: "vx-row mb-6" }, [
                            _vm._m(13, true),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full" },
                              [
                                _c("v-select", {
                                  attrs: {
                                    name: "telephoneType_" + phoneIndex,
                                    reduce: (x) => x.code,
                                    disabled: _vm.readOnly,
                                    clearable: false,
                                    options: _vm.telephoneType,
                                  },
                                  model: {
                                    value:
                                      _vm.form.customerPhones[phoneIndex]
                                        .telephoneType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.customerPhones[phoneIndex],
                                        "telephoneType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.customerPhones[phoneIndex].telephoneType",
                                  },
                                }),
                                _vm.errors.has("telephoneType_" + phoneIndex)
                                  ? _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors
                                              .first(
                                                "telephoneType_" + phoneIndex
                                              )
                                              .replace(
                                                "The telephoneType_" +
                                                  phoneIndex +
                                                  " field is required",
                                                "Telephone Type is required"
                                              )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                          _c("div", { staticClass: "vx-row mb-6" }, [
                            _vm._m(14, true),
                            _c("div", { staticClass: "vx-col w-full" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      reduce: (x) => x.code,
                                      name: "telephonePrefix_" + phoneIndex,
                                      disabled: _vm.readOnly,
                                      options:
                                        _vm.customerFormData.telePhonePrefix,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerPhones[phoneIndex]
                                          .telephonePrefix,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerPhones[phoneIndex],
                                          "telephonePrefix",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerPhones[phoneIndex].telephonePrefix",
                                    },
                                  }),
                                  _vm.errors.has(
                                    "telephonePrefix_" + phoneIndex
                                  )
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "telephonePrefix_" +
                                                    phoneIndex
                                                )
                                                .replace(
                                                  "The telephonePrefix_" +
                                                    phoneIndex +
                                                    " field is required",
                                                  "Telephone Prefix is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                          _c("div", { staticClass: "vx-row mb-6" }, [
                            _vm._m(15, true),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full" },
                              [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        phoneNumber: true,
                                        required: true,
                                      },
                                      expression:
                                        "{ phoneNumber:true, required:true }",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    type: "tel",
                                    name: "telephoneNumber_" + phoneIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.form.customerPhones[phoneIndex]
                                        .telephoneNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.customerPhones[phoneIndex],
                                        "telephoneNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.customerPhones[phoneIndex].telephoneNumber",
                                  },
                                }),
                                _vm.errors.has("telephoneNumber_" + phoneIndex)
                                  ? _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors
                                              .first(
                                                "telephoneNumber_" + phoneIndex
                                              )
                                              .replace(
                                                "The telephoneNumber_" +
                                                  phoneIndex +
                                                  " field is required",
                                                "Telephone Number is required"
                                              )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                          _c("div", { staticClass: "vx-row mb-6" }, [
                            _vm._m(16, true),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full" },
                              [
                                _c("vs-switch", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    name: "telephoneIsPrimary_" + phoneIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.resetPrimaryPhone(
                                        $event,
                                        phoneIndex
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.form.customerPhones[phoneIndex]
                                        .telephoneIsPrimary,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.customerPhones[phoneIndex],
                                        "telephoneIsPrimary",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.customerPhones[phoneIndex].telephoneIsPrimary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  !_vm.readOnly
                    ? _c(
                        "div",
                        {
                          key: phoneIndex + "1",
                          staticClass: "vx-col w-full md:w-1/6 flex",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "m-auto" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-8",
                                  class: _vm.readOnly
                                    ? "opacity-50 cursor-not-allowed"
                                    : "",
                                  attrs: {
                                    size: "small",
                                    type: "border",
                                    color: "danger",
                                    disabled: _vm.readOnly,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDynamicFields(
                                        phoneIndex,
                                        "phones"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Remove")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
              !_vm.readOnly
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mb-3" },
                    [
                      _c(
                        "vs-button",
                        {
                          class: _vm.readOnly
                            ? "opacity-50 cursor-not-allowed"
                            : "",
                          attrs: {
                            size: "small",
                            type: "border",
                            color: "success",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDynamicFields("phones")
                            },
                          },
                        },
                        [_vm._v("Add Phone")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("vs-divider", { staticClass: "mb-5" }),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full md:w-1/2 mb-6" }, [
              _c(
                "div",
                { staticClass: "flex items-end md:mt-0 mt-base" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "leading-none font-medium" }, [
                    _vm._v("Address"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(
                _vm.form.customerAddresses,
                function (address, addressIndex) {
                  return [
                    addressIndex > 0
                      ? _c("vs-divider", {
                          key: addressIndex + "00",
                          staticClass: "mb-5",
                          attrs: { "border-style": "dotted", color: "primary" },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        key: addressIndex + "0",
                        staticClass: "vx-col w-full md:w-5/6",
                      },
                      [
                        _c("div", { staticClass: "vx-row" }, [
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(17, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:50",
                                        expression: "'required|max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      name: "addressName_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressName",
                                    },
                                  }),
                                  _vm.errors.has("addressName_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "addressName_" + addressIndex
                                                )
                                                .replace(
                                                  "The addressName_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Address Name is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(18, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "max:50",
                                        expression: "'max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      name: "addressCompany_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressCompany,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressCompany",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressCompany",
                                    },
                                  }),
                                  _vm.errors.has(
                                    "addressCompany_" + addressIndex
                                  )
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "addressCompany_" +
                                                    addressIndex
                                                )
                                                .replace(
                                                  "The addressCompany_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Address is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(19, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:50",
                                        expression: "'required|max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      name: "addressLine1_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressLine1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressLine1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressLine1",
                                    },
                                  }),
                                  _vm.errors.has("addressLine1_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "addressLine1_" + addressIndex
                                                )
                                                .replace(
                                                  "The addressLine1_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Address  Line 1 is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(20, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "max:50",
                                        expression: "'max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      name: "addressLine2_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressLine2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressLine2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressLine2",
                                    },
                                  }),
                                  _vm.errors.has("addressLine2_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "addressLine2_" + addressIndex
                                                )
                                                .replace(
                                                  "The addressLine2_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Address is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(21, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:50",
                                        expression: "'required|max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      name: "city_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressCity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressCity",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressCity",
                                    },
                                  }),
                                  _vm.errors.has("city_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first("city_" + addressIndex)
                                                .replace(
                                                  "The city_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "City is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(22, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "max:50",
                                        expression: "'max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      name: "state_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressState",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressState",
                                    },
                                  }),
                                  _vm.errors.has("state_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first("state_" + addressIndex)
                                                .replace(
                                                  "The state_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Address is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(23, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:50",
                                        expression: "'required|max:50'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "text",
                                      name: "postcode_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressPostalCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressPostalCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressPostalCode",
                                    },
                                  }),
                                  _vm.errors.has("postcode_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "postcode_" + addressIndex
                                                )
                                                .replace(
                                                  "The postcode_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Post Code is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(24, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      reduce: (x) => x.code,
                                      name: "country_" + addressIndex,
                                      disabled: _vm.readOnly,
                                      options:
                                        _vm.customerFormData.countriesCode,
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressCountryCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressCountryCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressCountryCode",
                                    },
                                  }),
                                  _vm.errors.has("country_" + addressIndex)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger text-sm" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors
                                                .first(
                                                  "country_" + addressIndex
                                                )
                                                .replace(
                                                  "The country_" +
                                                    addressIndex +
                                                    " field is required",
                                                  "Country is required"
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mb-6" }, [
                              _vm._m(25, true),
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-switch", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      name: "addressIsPrimary_" + addressIndex,
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.resetPrimaryAddress(
                                          $event,
                                          addressIndex
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.form.customerAddresses[addressIndex]
                                          .addressIsPrimary,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.customerAddresses[
                                            addressIndex
                                          ],
                                          "addressIsPrimary",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.customerAddresses[addressIndex].addressIsPrimary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    !_vm.readOnly
                      ? _c(
                          "div",
                          {
                            key: addressIndex + "1",
                            staticClass: "vx-col w-full md:w-1/6 flex",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "m-auto" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mt-8",
                                    class: _vm.readOnly
                                      ? "opacity-50 cursor-not-allowed"
                                      : "",
                                    attrs: {
                                      size: "small",
                                      type: "border",
                                      color: "danger",
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDynamicFields(
                                          addressIndex,
                                          "address"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                }
              ),
              !_vm.readOnly
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mb-3" },
                    [
                      _c(
                        "vs-button",
                        {
                          class: _vm.readOnly
                            ? "opacity-50 cursor-not-allowed"
                            : "",
                          attrs: {
                            size: "small",
                            type: "border",
                            color: "success",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDynamicFields("address")
                            },
                          },
                        },
                        [_vm._v("Add Address")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("vs-divider", { staticClass: "mb-5" }),
          _vm._m(26),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.form.customFields, function (field, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "vx-col w-full" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.form.customFields[index].fieldName)),
                    ]),
                    _c("vs-textarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:500",
                          expression: "'max:500'",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: {
                        counter: "500",
                        maxlength: "500",
                        rows: "4",
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.form.customFields[index].fieldValue,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.customFields[index],
                            "fieldValue",
                            $$v
                          )
                        },
                        expression: "form.customFields[index].fieldValue",
                      },
                    }),
                  ],
                  1
                )
              }),
              !_vm.form.customFields || _vm.form.customFields.length == 0
                ? _c("div", { staticClass: "vx-col w-full mb-8" }, [
                    _c("div", { staticClass: "ml-4" }, [
                      _vm._v("No custom fields."),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "vx-row" }, [
            _vm.isEditForm
              ? _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _vm._m(27),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "Created Date",
                            value: _vm.createdDate,
                            disabled: true,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.isEditForm
              ? _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _vm._m(28),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "Updated Date",
                            value: _vm.updatedDate,
                            disabled: true,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._m(29),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-4 mr-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                    [_vm._v("Save")]
                  )
                : _vm._e(),
              _vm.readOnly && !_vm.isSystemRole
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-4 mr-4",
                      on: { click: _vm.handleEditMode },
                    },
                    [_vm._v("Edit")]
                  )
                : _vm._e(),
              _vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleCancel } },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full align-center" }, [
      _c("span", [_vm._v("Customer Id")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Title:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full align-center" }, [
      _c("span", [_vm._v("First Name")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Last Name:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Email:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Internal Reference:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Default Language:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Company Id:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Company Name:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Default Currency:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Membership Level:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Membership Subscription ID:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Membership Expiry:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Telephone Type:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Prefix:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Telephone No:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Is Primary:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Address Name:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Address Company:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Address Line 1:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Address Line 2:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("City:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("State:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Post Code:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Country:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("span", [_vm._v("Is Primary:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full md:w-1/2 mb-6" }, [
        _c("div", { staticClass: "flex items-end md:mt-0 mt-base" }, [
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Custom Fields"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full align-center" }, [
      _c("span", [_vm._v("Created Date:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full align-center" }, [
      _c("span", [_vm._v("Updated Date:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lg:float-left mt-4" }, [
      _c("span", { staticClass: "text-sm text-danger" }, [
        _vm._v("*Required Field"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }