var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Customer Details", gridWidth: "2/3" } },
    [
      _vm.allDataLoaded
        ? _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "tabs-container px-6 pt-6",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "vs-tabs",
                  {
                    key: 0,
                    staticClass: "tabs-shadow-none",
                    attrs: { id: "profile-tabs" },
                    model: {
                      value: _vm.currentTabIndex,
                      callback: function ($$v) {
                        _vm.currentTabIndex = $$v
                      },
                      expression: "currentTabIndex",
                    },
                  },
                  [
                    _c("vs-tab", { attrs: { label: "Details" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-general md:ml-0 md:mt-0 mt-0 ml-0",
                        },
                        [
                          _c("customer-editor", {
                            attrs: {
                              readOnly: _vm.readOnly,
                              customerId: _vm.customerId,
                              customerData: _vm.customerData,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-tab", { attrs: { label: "Orders" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0",
                        },
                        [
                          _c("customer-order-list", {
                            attrs: {
                              applicationId: _vm.applicationId,
                              data: _vm.orders,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-tab", { attrs: { label: "Hotlists" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0",
                        },
                        [
                          _c("customer-hotlist", {
                            attrs: {
                              applicationId: _vm.applicationId,
                              customerId: _vm.customerId,
                              data: _vm.hotlists,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }